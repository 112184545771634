@import url(https://fonts.googleapis.com/css?family=Roboto);
p{
    font-size: 17px !important;
}
.vertical-timeline-element-title{
    font-size:20px;
}
.vertical-timeline-element-subtitle{
    font-size: 17px;
}
#ballstateLogo{
    height:30vh;
}
#containerEdu{
    text-align: center;
    padding: 25px;
}
#card-container{
    margin: 10px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
html,
body {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  color: #333;
  background: #eeeded;
}

.MuiIconButton-label-55 {
  color: white;
}

.MuiButton-label-259 {
  color: white;
  font-weight: 100;
}

.container {
  margin: 16px;
}

