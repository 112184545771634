#ballstateLogo{
    height:30vh;
}
#containerEdu{
    text-align: center;
    padding: 25px;
}
#card-container{
    margin: 10px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}